//COLORS
$color-primary: #e90016;
$color-primary-light: #fa0404;
$color-primary-dark: #d3080f;
$color-grey-dark-1: #1d1d1d;
$color-tags: #5b5b5b;
$color-description: #7d7d7d;
$color-nav: #c5c3b7;
$color-number: hsla(0,0%,64.3%,0.158);
$color-icons: #a49b9f;
$color-heading: #f4eaf1;
$color-heading-small: #f4eaf1;
$color-paragraph: #ccc;
$color-icons-light: #f4eaf1;
$color-aboutme: #222222;
$color-white: #fff;
$color-form: #303030;
$color-form-text: #a4a4a4;
$color-card: #2c2c2c;
$color-line: #494949;
$color-date: #999;
