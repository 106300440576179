@import '../../../sassutils/master';

.homepage {
    background-color: $color-grey-dark-1;
    width: 127rem;
    height: 100%;
    overflow: hidden;
    margin-left: 9.55rem;

    &__buttons {
        @media screen and (max-width: 25em) {
            width: 89% !important;
            margin-left: 1rem !important;
        }
    }
    
    @media screen and (max-width: 68.75em) {
        flex-direction: column;
        padding-top: 5rem;
        width: 100%;
        margin-left: 0;
    }

    @media screen and (max-width: 48.75em) {
        padding-left: 6rem;
        padding-top: 10rem;
    }

    @media screen and (max-width: 34.375em) {
        width: 100%;
        padding-left: 0;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .content {
        position: absolute;
        top: -16.5rem;
        right: -36.5rem;
        z-index: 0;

        @media screen and (max-width: 68.75em) {
            top: -4.5rem;
            right: -33.9rem;
        }

        @media screen and (max-width: 56.25em) {
            top: -20rem;
            right: -33.9rem;
        }

        @media screen and (max-width: 35em) {
            position: relative;
            top: -30rem;
        }
        
        @media screen and (max-width: 35em) {
            position: relative;
            top: -20rem;
        }

        &__img-big {
            position: relative;
            z-index: 2;
        }

        &__img {
            position: relative;
            width: 70vw;
            z-index: 2;

            @media screen and (max-width: 68.75em) {
                width: 100rem;
            }
            
            @media screen and (max-width: 56.25em) {
                width: 100rem;
            }

            @media screen and (max-width: 48.750em ) { //750px
                position: relative;
                top: 30rem;
                right: 20rem;
                width: 80rem;
            }

            @media screen and (max-width: 35em) {
                position: relative;
                top: -30rem;
                right: 40rem;
                width: 80rem;
            }
            
            @media screen and (max-width: 25em) {
                position: relative;
                top: -30rem;
                right: 40rem;
                width: 80rem;
            }
        }

        &__number-large {
            font-family: 'Roboto', sans-serif;
            font-size: 70rem;
            color: $color-number;
            font-weight: 700;
            z-index: 1;
            display: inline-block;
            line-height: 1;
            position: absolute;
            left: auto;
            bottom: -7rem;
            right: 21rem;   
            
            @media screen and (max-width: 68.75em) {
                font-size: 55rem;
                position: relative;
                bottom: 40rem;
                left: 50rem;
            }

            @media screen and (max-width: 48.75em) {
                font-size: 60rem;
                position: relative;
                bottom: 10rem;
                left: -10rem;
            }

            @media screen and (max-width: 35em) {
                position: relative;
                bottom: 85rem;
                right: 35rem;
            }
            
            @media screen and (max-width: 25em) {
                position: relative;
                bottom: 75rem;
                right: 35rem;
                font-size: 45rem;
            }

        }
    }
}

.heading-primary {
    margin-left: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    z-index: 5;
    padding-top: .8rem;

    &__closing-tags {
        margin-top: 7rem;
        z-index: 100;
    }

    &__tag {
        display: block;
        font-family: 'La Belle Aurore', cursive, sans-serif;
        color: $color-tags;
        font-size: 2.5rem;
        z-index: 10;

        &-heading {
            @media screen and (max-width: 48.750em ) { //750px
                font-size: 2rem;
            }

            @media screen and (max-width: 25em) { //400px
                font-size: 1.7rem;
            }
        }

        &:last-child {
            margin-left: 3rem;
        }

        &::before {
            content: '<';
        }

        &::after {
            content: '>';
        }

        &-1 {
            align-self: flex-end;
            margin-left: -2rem;
        }

        &-close {
            margin-left: 1.8rem;

            &:last-child {
                margin-top: -.5rem;
                margin-left: -.5rem;
            }
        }
    }

    &__body {
        margin-left: 7rem;
        margin-top: 1rem;

        @media screen and (max-width: 48.750em) {
            margin-left: 0;
        }
    }
}

.custom-heading {
    
    &__red {
        color: $color-primary;
    }

    &__desc {
        margin-left: 3rem;
        font-size: 1.8rem;
        color: $color-description;
        z-index: 200;
        position: relative;

        @media screen and (max-width: 46.857em) {
            font-size: 1.4rem;
        }
    }

    &__button {
        @media screen and (max-width: 40.625em) {
            font-size: 1.3rem;
        }
    }
}

.custom-heading__buttons {
    margin-top: 4rem;
    display: flex;
    width: 36%;
    margin-left: 3rem;
    justify-content: center;

    @media screen and (max-width: 56.25em) {
        width: 45%;
    }

    @media screen and (max-width: 40.625em) {
        width: 75%;
    }
}

.typewriter {
    display: block;
    overflow: hidden;
    position: relative;

    &__header {
        font-family: 'Roboto', sans-serif;
        position: relative;
        white-space: nowrap;
        font-size: 6.5rem;
        line-height: 1.05;
        margin-left: 3rem;
        font-weight: 900;
        color: #f4eaf1;
        z-index: 20;

        @media screen and (max-width: 1100px) {
            font-size: 5rem;
        }

        @media screen and (max-width: 900px) {
            font-size: 3.5rem;
        }

        @media screen and (max-width: 750px) {
            font-size: 2.5rem;
        }

        @media screen and (max-width: 400px) {
            font-size: 2rem;
        }
    }

    &-1 {
        width: 0;
        border-right: .15em solid white;
        animation: typewriter-1 .4s steps(44) .2s 1 normal both,
        blinkTextCursor 300ms steps(44) none 3;
        animation-fill-mode: forwards;

        @media screen and (max-width: 68.75em) {
            animation: typewriter-1-1100px .4s steps(44) .2s 1 normal both,
            blinkTextCursor 300ms steps(44) none 3;
            animation-fill-mode: forwards;
        }

        @media screen and (max-width: 56.25em) {
            animation: typewriter-1-900px .4s steps(44) .2s 1 normal both,
            blinkTextCursor 300ms steps(44) none 3;
            animation-fill-mode: forwards;
        }

        @media screen and (max-width: 46.825em) {
            animation: typewriter-1-750px .4s steps(44) .2s 1 normal both,
            blinkTextCursor 300ms steps(44) none 3;
            animation-fill-mode: forwards;
        }

        @media screen and (max-width: 25em) {
            animation: typewriter-1-400px .4s steps(44) .2s 1 normal both,
            blinkTextCursor 300ms steps(44) none 3;
            animation-fill-mode: forwards;
        }
    }

    &-2 {
        width: 0;
        border-right: .15em solid white;
        animation: typewriter-2 .4s steps(44) 1s 1 normal both,
        blinkTextCursor 300ms steps(44) 1s 4;
        animation-fill-mode: forwards;

        @media screen and (max-width: 68.75em) {
            animation: typewriter-2-1100px .4s steps(44) 1s 1 normal both,
            blinkTextCursor 300ms steps(44) 1s 4;
            animation-fill-mode: forwards;
        }

        @media screen and (max-width: 56.25em) {
            animation: typewriter-2-900px .4s steps(44) 1s 1 normal both,
            blinkTextCursor 300ms steps(44) 1s 4;
            animation-fill-mode: forwards;
        }

        @media screen and (max-width: 46.825em) {
            animation: typewriter-2-750px .4s steps(44) 1s 1 normal both,
            blinkTextCursor 300ms steps(44) 1s 4;
            animation-fill-mode: forwards;
        }

        @media screen and (max-width: 25em) {
            animation: typewriter-2-400px .4s steps(44) 1s 1 normal both,
            blinkTextCursor 300ms steps(44) 1s 4;
            animation-fill-mode: forwards;
        }
    }

    &-3 {
        width: 0;
        border-right: .15em solid white;
        animation: typewriter-3 1s steps(44) 2s 1 normal both,
        blinkTextCursor 300ms steps(44) 2s 5;
        animation-fill-mode: forwards;
        display: inline-block;

        
        @media screen and (max-width: 68.75em) {
            animation: typewriter-3-1100px 1s steps(44) 2s 1 normal both,
            blinkTextCursor 300ms steps(44) 2s 5;
            animation-fill-mode: forwards;
        }
        
        @media screen and (max-width: 56.25em) {
            animation: typewriter-3-900px 1s steps(44) 2s 1 normal both,
            blinkTextCursor 300ms steps(44) 2s 5;
            animation-fill-mode: forwards;
        }

        @media screen and (max-width: 46.825em) {
            animation: typewriter-3-750px 1s steps(44) 2s 1 normal both,
            blinkTextCursor 300ms steps(44) 2s 5;
            animation-fill-mode: forwards;
        }

        @media screen and (max-width: 25em) {
            animation: typewriter-3-400px 1s steps(44) 2s 1 normal both,
            blinkTextCursor 300ms steps(44) 2s 5;
            animation-fill-mode: forwards;
        }
    }

}

@keyframes typewriter-1 {
    from {
        width: 0;
    } to {
        width: 13.5rem;
    }
}

@keyframes typewriter-1-1100px {
    from {
        width: 0;
    } to {
        width: 10.5rem;
    }
}

@keyframes typewriter-1-900px {
    from {
        width: 0;
    } to {
        width: 7.5rem;
    }
}

@keyframes typewriter-1-750px {
    from {
        width: 0;
    } to {
        width: 4.8rem;
    }
}

@keyframes typewriter-1-400px {
    from {
        width: 0;
    } to {
        width: 4rem;
    }
}

@keyframes typewriter-2 {
    from {
        width: 0;
    }

    to {
        width: 25rem;
    }
}

@keyframes typewriter-2-1100px {
    from {
        width: 0;
    }

    to {
        width: 19.2rem;
    }
}

@keyframes typewriter-2-900px {
    from {
        width: 0;
    }

    to {
        width: 13rem;
    }
}

@keyframes typewriter-2-750px {
    from {
        width: 0;
    }

    to {
        width: 9.7rem;
    }
}

@keyframes typewriter-2-400px {
    from {
        width: 0;
    }

    to {
        width: 8rem;
    }
}

@keyframes typewriter-3 {
    from {
        width: 0;
    }

    to {
        width: 60.2rem;
    }
}

@keyframes typewriter-3-1100px {
    from {
        width: 0;
    }

    to {
        width: 46.3rem;
    }
}

@keyframes typewriter-3-900px {
    from {
        width: 0;
    }

    to {
        width: 32.2rem;
    }
}

@keyframes typewriter-3-750px {
    from {
        width: 0;
    }

    to {
        width: 23.3rem;
    }
}

@keyframes typewriter-3-400px {
    from {
        width: 0;
    }

    to {
        width: 20.5rem;
    }
}

@keyframes blinkTextCursor {
    from {
        border-right: .15em solid white;
        border-right-color: white;
    }

    to {
        border-right: none;
        border-right-color: transparent;
    }
}

@keyframes removeBlinker {
    to {
        border: none;
    }
}