@import '../../../sassutils/master';

.mywork {
    background-color: $color-grey-dark-1;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    padding-left: 9.55rem;
    overflow-y: scroll;

    &::-webkit-scrollbar {
        width: 12px;
    }
    
        /* Track */
    &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 2px grey; 
            border-radius: 10px;
        }
    
        /* Handle */
    &::-webkit-scrollbar-thumb {
            background: rgb(189, 189, 189); 
            border-radius: 10px;
        }
    
        /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
            background: rgb(7, 7, 7); 
        }

        
    @media screen and (max-width: 68.750em) {
        width: 100%;
        height: 100%;
        padding-top: 10rem;
        padding-left: 0;
    }
    
    @media screen and (max-width: 31.25em) {
        &::-webkit-scrollbar {
            display: none;
        }
    }

    &__heading {
        font-size: 3rem;
        color: $color-heading;
        margin-left: 12rem;
        margin-top: 1rem;

        @media screen and (max-width: 1100px) {
            margin: 0;
            display: flex;
            justify-content: center;
        }
    }

    &__projects {
        margin-top: 5rem;
    }
}