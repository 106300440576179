* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

a, button, div, li, select {
  --webkit-tap-highlight-color: transparent;
}

html {
  font-size: 62.5%;
  position: relative;
  background-color: #1d1d1d;
  overflow: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body, #root, .App {
  height: 100%;
  overflow: hidden;
}

/* ::-webkit-scrollbar {
  display: none !important;
} */

.App {
  display: flex;
}

::-webkit-scrollbar {
  width: 12px;
}

  /* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px grey; 
  border-radius: 10px;
}

  /* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(189, 189, 189); 
  border-radius: 10px;
}

  /* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(7, 7, 7); 
}
