@import '../../sassutils/master';
@import './mixins';

.header {
    width: 10rem;
    background-color: $color-grey-dark-1;
    color: $color-nav;
    height: 62.5rem;
    font-size: 1.53rem;
    font-weight: 400;
    position: fixed;
    z-index: 100;

    @media screen and (min-width: 87.5em) {
        height: 100%;
    }

    @media screen and (max-width: 68.75em) {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 8rem;
        background-color: transparent;
        position: fixed;
    }

    @media screen and (max-width: 25em) {
        width: 36rem;
    }

    &__social-container {
        list-style: none;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-left: -3rem;

        @media screen and (max-width: 68.75em) {
            flex-direction: row;
            justify-content: space-between;
            margin-left: 3.3rem;
            margin-right: .7rem;
            margin-top: .9rem;
        }

        @media screen and (max-width: 48.750em) {
            display: none;
        }
    }

    &__social {
        &:first-child {
            margin-top: 3rem;

            @media screen and (min-width: 87.5em) {
                margin-top: 6rem;
            }

            @media screen and (max-width: 68.75em) {
                margin: 0;
            }
        }
    }

    &__social-icon {
        width: 2.5rem;
        height: 2.5rem;
        fill: $color-nav;
        margin-bottom: 2rem;

        @media screen and (min-width: 87.5em) {
            margin-bottom: 3.5rem;
        }

        @media screen and (max-width: 68.75em) {
            margin-right: 2rem;
        }

        &:hover {
            fill: $color-primary;
        }
    }

    &__logo {
        width: 6.3rem;
        margin-top: 1.5rem;
        margin-left: 1.5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-decoration: none;
        position: fixed;

        @media screen and (max-width: 68.75em) {
            flex-direction: row;
            margin-left: 4rem;
            width: 10rem;
        }

        &-img {
            width: 5rem;
            height: 5rem;
            margin-bottom: .2rem;
            @media screen and (max-width: 68.75em) {
                margin-right: .5rem;
            }
        }

        &-companyname {
            display: flex;
            flex-direction: column;
            align-items: center;
            @media screen and (max-width: 68.75em) {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
            }
        }

        & span {
            color: white;
            font-size: 1.1rem;
        }
    }

    &__navigation {
        width: inherit;

        &-menu {
            display: none;
            flex-direction: column;
            position: relative;
            align-self: center;
            margin-right: 3rem;

            @media screen and (max-width: 48.750em) {
                display: flex;
            }
        }
    }

    &__item-container {
        padding-top: 25vh;

        @media screen and (min-width: 87.5em) {
            padding-top: 30vh;
        }

        @media screen and (max-width: 68.75em) {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-top: 2rem;
        }

        @media screen and (max-width: 48.750em) {
            display: none;
        }
    }

    &__item {
        margin-left: 2rem;
        list-style: none;
        padding: 1.2rem 0;
        transform: scale(1) rotate(0);
        transition: all .3s;
        
        &:hover {
            transform: scale(1.2) rotate(-5deg);
            box-shadow: 0 1rem 2rem rgba(0,0,0,.4);
            color: $color-primary;
        }
        
        &:nth-child(1) {
            width: 4.5rem;
        }
        &:nth-child(2) {
            width: 7rem;
        }
        &:nth-child(3) {
            width: 7.5rem;
        }
        &:nth-child(4) {
            width: 6rem;
        }
        &:nth-child(5) {
            width: 4rem;
        }

        &:not(:last-child) {
            margin-bottom: 1.5rem;
        }

        &-link {
            color: inherit;
            cursor: pointer;
            text-decoration: none;
        }

        &-link-active {
            color: $color-primary;
        }
    }
}

.navigation {

    //FUNCTIONALITY
    &__checkbox:checked ~ &__background {
        transform: scale(100);
    }
    
    &__checkbox:checked ~ &__nav {
        opacity: 1;
        width: 100%;
        visibility: visible;
        
    }
    
    &__checkbox {
        display: none;
    }

    &__button {
        cursor: pointer;
        width: 4rem;
        height: 4rem;
        border-radius: 50%;
        background-color: transparent;
        position: fixed;
        top: 2rem;
        right: 2rem;
        z-index: 20;
        text-align: center;
        box-shadow: 0 1rem 3rem rgba(0,0,0, .2);
    }

    &__background {
        width: 4rem;
        height: 4rem;
        border-radius: 50%;
        background-color: rgba($color: black, $alpha: 0.8);
        position: fixed;
        top: 2.1rem;
        right: 2rem;
        z-index: 10;
        opacity: 1;
        transition: all .7s cubic-bezier(0.86, 0, 0.07, 1);

        transform: scale(1);
    }

    &__nav {
        height: 100vh;
        width: 0%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 15;
        opacity: 0;
        visibility: hidden;
        transition: width .6s, opacity .4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    }

    &__list {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        list-style: none;
        text-align: center;
        width: 100%;
    }

    &__link {
        display: inline-block;
        margin: 1rem;

        span {
            margin-right: 1rem;
        }

        &:link,
        &:visited { 
            font-size: 2rem;
            font-weight: 300;
            text-decoration: none;
            text-transform: uppercase;
            color: $color-white;
            padding: 1.2rem 2rem;
            background-image: linear-gradient(120deg, transparent 0%, transparent 50%, $color-white 50%);
            background-size: 230.5%;
            transition: all .6s;
        }

        &:hover,
        &:active {
            background-position: 100%;
            color: black;
            transform: translateX(1rem);
        }
    }

    //ICON
    &__icon {
        position: relative;
        margin-top: 2rem;

        &,
        &::before,
        &::after {
            width: 1.8rem;
            height: 1px;
            background-color: $color-white;
            display: inline-block;
        }

        &::before,
        &::after {
            content: "";
            position: absolute;
            left: 0;
            transition: all .2s ease;
        }

        &::before { top: -.7rem; }
        &::after { top: .7rem; }
    }

    &__button:hover &__icon::before {
        top: -.8rem;
    }

    &__button:hover &__icon::after {
        top: .8rem;
    }

    &__checkbox:checked + &__button &__icon {
        background-color: transparent;

        &::before { top:0; transform: rotate(-45deg);}
        &::after { top:0; transform: rotate(45deg);}
    }
}