@import '../../../sassutils/master';

.blog {
    background-color: $color-grey-dark-1;
    width: 127rem;
    height: 100%;
    margin-left: 9.55rem;
    overflow-x: hidden;
    padding-top: 3rem;

    @media screen and (max-width: 68.75em) {
        width: 100%;
        margin-left: 0;
        padding: 0 4rem;
        padding-top: 9rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__heading {
        @media screen and (max-width: 68.75em) {
            margin-left: -4rem;
        }
    }

    &__desc {
        padding-left: 12rem;

        @media screen and (max-width: 68.75em) {
            padding-left: 0;
        }
    }
}